import * as React from "react"

import bostonGlobeLogo from '../images/media/press/boston-globe-logo.webp'
import steveAhlquistLogo from '../images/media/press/steve-ahlquist-logo.webp'
import thePublicsRadioLogo from '../images/media/press/the-publics-radio-logo.webp'

const press = [
  {
    "logo": `${thePublicsRadioLogo}`,
    "date": "5/5/2023",
    "headline": "As the son of an immigrant mother, I understand better than anyone else in this field the struggles of working families in Rhode Island.",
    "quote": "\"With rising inflation, it's getting harder every single day for Rhode Islanders to get by. My goal is to go to DC, and fight for those people and advocate for them every single day.<br /><br />And ultimately, <strong>every single day, I wake up at the crack of dawn, to serve students, to serve families, to serve my community...I was raised in Providence, I was raised in CD-1</strong>.<br /><br />So I think I'm the right person to lead during this time.\"",
    "linkUrl": "https://thepublicsradio.org/episode/political-roundtable-goncalves-on-his-run-in-cd1-providence-schools-and-taxes-for-non-profits-"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "7/24/2023",
    "headline": "I'm also a teacher. That's what differentiates me from the rest of the people on this stage.",
    "quote": "\"<strong>This work is not theoretical for me.</strong><br /><br />I grew up in poverty, to a single parent mother in the city of Providence, and being a person of color, my lived experience is exactly why I'm in this fight. There are so many people who need folks like me to be a part of these fights.\"",
    "linkUrl": "https://www.bostonglobe.com/2023/07/24/metro/democrats-agree-issues-differ-background-experience/"
  },
  {
    "logo": `${steveAhlquistLogo}`,
    "date": "7/26/2023",
    "headline": "We need strong leaders in Washington who aren't just going to use all the talking points that sound good, but people who have dealt with those experiences in a real way. I think that separates me from the field, in addition to being a teacher.",
    "quote": "\"During the height of the pandemic, there was a woman who reached out to me and was afraid to go out. She was an elderly woman. She was afraid to go to the grocery store because of the challenges associated with that. In response, we launched a program called Produce in the Parks that allowed people, particularly seniors, to access fresh fruits and vegetables at a park where they could pay with SNAP and EBT. <strong>By solving one person's problem, we were able to solve the problem for hundreds of people. That's the kind of leadership I've exhibited at the local level and that's the job of a Congressperson.</strong> Your job is to deliver the best and the highest quality constituent services.\"",
    "linkUrl": "https://steveahlquist.substack.com/p/pvd-city-councilmember-john-goncalves"
  },
]

export default function HomePagePress() {
  return (
    <div className="relative bg-gray-100 px-4 sm:px-6 lg:px-8">  
      <div className="relative max-w-7xl mx-auto pt-8 pb-8">
        <h1 className="font-bold text-6xl">Featured Press</h1>
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {press.map((post) => (
            <div key={post.title} className="card flex flex-col shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="w-full object-cover" src={post.logo} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-orange-headline">
                    {post.date}
                  </p>
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.headline}</p>
                    <p className="text-lg text-gray-500" dangerouslySetInnerHTML={{ __html: post.quote }}></p>
                  </div>
                  <a href={post.linkUrl} className="block mt-2" target="_blank">Read More &raquo;</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
