import * as React from "react"

import homePageCollage from '../images/home-page/home-page-collage.webp'

export default function HomePageCollage() {
  return (
    <div className="bg-blue-bgDark">
      <div className="pb-10">
        <img src={homePageCollage} />
      </div>
    </div>
  )
}
