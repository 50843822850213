import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HomePageCollage from "../components/home-page-collage"
import HomePageHero from "../components/home-page-hero"
import HomePageBio from "../components/home-page-bio"
import HomePagePress from "../components/home-page-press"
import HomePageVideos from "../components/home-page-videos"
import HomePageIssues from "../components/home-page-issues"
import HomePageFeaturedImage from "../components/home-page-featured-image"
import HomePageVideo from "../components/home-page-video"

const IndexPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Home" excludePageTitle="true" />
    <HomePageCollage />
    <HomePageBio />
    <HomePagePress />
    <HomePageVideos />
    <HomePageIssues />
    <HomePageVideo />
    <HomePageHero />
    <HomePageFeaturedImage />
  </Layout>
)

export default IndexPage
