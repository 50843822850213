import * as React from "react"
import { navigate } from 'gatsby'
import homePageHeroJohnGoncalves from '../images/home-page/home-page-hero-john-goncalves.webp'

const onFormSubmit = e => {
  e.preventDefault();

  let homePageHeroNgpVanEmailSignupForm = document.querySelector('.home-page-hero-ngp-van-email-signup-form'),
      homePageHeroNgpVanEmailSignupSuccess = document.querySelector('.home-page-hero-ngp-van-email-signup-success'),
      homePageHeroNgpVanEmailSignupAlreadySubscribed = document.querySelector('.home-page-hero-ngp-van-email-signup-already-subscribed'),
      homePageHeroNgpVanEmailSignupError = document.querySelector('.home-page-hero-ngp-van-email-signup-error');

  const XHR = new XMLHttpRequest();

  // Bind the FormData object and the form element
  const FD = new FormData(e.target);

  // Define what happens on successful data submission
  XHR.addEventListener('load', (event) => {
    fadeOut(homePageHeroNgpVanEmailSignupForm);
    fadeIn(homePageHeroNgpVanEmailSignupSuccess);

    navigate('https://secure.actblue.com/donate/john-goncalves-website-signup?refcode=website-home-page-hero');
  });

  // Define what happens in case of error
  XHR.addEventListener('error', (event) => {
    fadeIn(homePageHeroNgpVanEmailSignupError);
  });

  // Set up our request
  XHR.open('POST', 'https://actions.ngpvan.com/v2/Forms/MO5Re5Vt9kO1YtfIFtjjBQ2');

  // Add the required HTTP header for form data POST requests
  XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

  // The data sent is what the user provided in the form
  XHR.send(new URLSearchParams(FD));
}

// fade out
const fadeOut = function fadeOut(el){
  el.style.opacity = 1;

  (function fade() {
    if ((el.style.opacity -= .1) < 0) {
      el.style.display = "none";
    } else {
      requestAnimationFrame(fade);
    }
  })();
}

// fade in
const fadeIn = function fadeIn(el, display){
  el.style.opacity = 0;
  el.style.display = display || "block";

  (function fade() {
    var val = parseFloat(el.style.opacity);
    var proceed = ((val += 0.1) > 1) ? false : true;
    
    if (proceed) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  })();
}

export default function HomePageHero() {
  return (
    <div className="flex flex-col-reverse lg:flex-col relative text-white">
      <div className="flex-none mx-auto max-w-7xl w-full pt-10 pb-10 text-center lg:text-left">
        <div className="px-4 xl:px-0 lg:w-1/2 xl:pr-16">
          <h1 className="text-3xl tracking-tight font-bold sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl">
            <span className="block xl:inline"><span className="text-aqua-headline">John Goncalves is running for Congress to fight for a brighter future for Rhode Island families.</span></span>
          </h1>
          <h2 className="text-2xl tracking-tight font-semibold sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl">
            <span className="block xl:inline"><span className="text-aqua-headline">Together</span>, let's move Rhode Island forward.</span>
          </h2>
          <div className="home-page-hero-ngp-van-email-signup-form">
            <p className="max-w-md mx-auto text-lg sm:text-xl md:mt-5 md:max-w-3xl">
                Sign up for newsletter updates &amp; to join John's grassroots team!
            </p>
            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              <form
                className="mt-3 text-left"
                method="POST"
                action="#"
                onSubmit={onFormSubmit}
              >
                <input type="hidden" name="SmsSubscribeMobilePhone.Value" id="SmsSubscribeMobilePhone_Value"  value="true" />
                <input type="hidden" name="YesSignMeUpForUpdatesForBinder.Value" id="YesSignMeUpForUpdatesForBinder_Value"  value="true" />
                <input type="hidden" name="SocialNetworkTrackingId.Value" id="SocialNetworkTrackingId_Value" />
                <input type="hidden" name="SocialNetwork.Value" id="SocialNetwork_Value" />
                <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                  <div>
                    <label htmlFor="first-name" className="block text-base font-medium">
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="FirstName"
                        id="first-name"
                        autoComplete="given-name"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        pattern="\D+"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="last-name" className="block text-base font-medium">
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="LastName"
                        id="last-name"
                        autoComplete="family-name"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        pattern="\D+"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="email" className="block text-base font-medium">
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="EmailAddress"
                        type="email"
                        autoComplete="email"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="tel" className="block text-base font-medium">
                      Mobile Phone Number (optional)
                    </label>
                    <div className="mt-1">
                      {/* TODO: validate this following https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/tel.*/ }
                      <input
                        id="tel"
                        name="MobilePhone"
                        type="tel"
                        autoComplete="tel"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-4 gap-x-4">
                  <div className="col-span-1">
                    <label htmlFor="zip-code" className="block text-base font-medium">
                      ZIP Code
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="PostalCode"
                        id="zip-code"
                        autoComplete="zip-code"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        pattern="(\d{5}([\-]\d{4})?)"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-2 pt-6">
                    <button
                    type="submit"
                    className="w-full h-full border border-transparent text-lg font-medium rounded-md text-white bg-orange-button shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:items-center"
                    >
                      Join Our Team
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-sm mb-0">By providing your mobile phone number, you consent to receive recurring text messages from John Goncalves for Congress.  Message & Data Rates May Apply.  Text STOP to opt out.</p>
                </div>
              </form>
            </div>
          </div>
          <div className="home-page-hero-ngp-van-email-signup-success hidden">
            <p className="max-w-md mx-auto text-lg sm:text-xl md:max-w-3xl">
              Thank you for joining our team!
            </p>
            <p className="max-w-md mx-auto text-lg sm:text-xl md:max-w-3xl">
              Make a donation to help elect John Goncalves! Every dollar fuels our grassroots campaign.

              Now redirecting you to a donation page...
            </p>
          </div>
          <div className="home-page-hero-ngp-van-email-signup-already-subscribed hidden">
            <p className="max-w-md mx-auto text-lg sm:text-xl md:max-w-3xl">
              Already subscribed&mdasho;thank you!
            </p>
          </div>
          <div className="home-page-hero-ngp-van-email-signup-error text-red hidden">
            <p className="max-w-md mx-auto text-lg sm:text-xl md:max-w-3xl">
              Error joining&mdash;please try again.
            </p>
          </div>
        </div>
      </div>
      <div className="flex-none relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
      {/*<div className="flex-none relative w-full lg:absolute lg:right-0 lg:w-1/2 lg:h-full pt-8 pr-8">*/}
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={homePageHeroJohnGoncalves}
          alt=""
        />
        {/*<ul
            role="list"
            className="absolute inset-0 w-full h-full object-cover space-y-8 sm:space-y-0 sm:grid sm:gap-x-8 sm:gap-y-8 lg:grid-cols-2 2xl:grid-cols-3" 
        >
            {people.map((person) => (
            <li key={person.name}>
                <div className="aspect-w-4 aspect-h-3">
                    <img className="object-cover" src={person.imageUrl} alt="" />
                </div>
            </li>
            ))}
            </ul>*/}
      </div>
    </div>
  )
}
