/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import * as React from "react"

export default function HomePageIssues() {
  return (
    <div className="relative bg-blue-bgDark text-white">
      <div className="mx-auto max-w-7xl w-full pb-8 grid lg:grid-cols-2">
        <div className="pt-8 pb-4 px-4 xl:px-0 xl:pr-16">
            <h2 className="text-6xl font-bold tracking-tight sm:text-6xl">Issues</h2>
            <h3 className="text-4xl font-semibold tracking-tight sm:text-4xl">John is rooted in community. John fights for all of us.</h3>
            <a
              href="/issues"
              className="mt-10 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xl sm:text-2xl font-medium font-bold text-white bg-blue-buttonLight hover:bg-blue-700"
            >
              See all of John's plans for RI-01
            </a>
        </div>
        <div className="lg:pl-0 pt-8 px-4 xl:px-0">
          <ul>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/education">&raquo; Education</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/climate-environmental-justice">&raquo; Climate &amp; Environmental Justice</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/invest-public-health-healthcare">&raquo; Invest in Public Health & Healthcare</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/planning-development-housing-housing-affordability">&raquo; Housing &amp; Housing Affordability</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/jobs-economic-justice">&raquo; Jobs &amp; Economic Justice</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/small-business-economy">&raquo; Small Business &amp; the Economy</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/improve-quality-of-life">&raquo; Improve Quality of Life</a></li>
            <li className="pb-1"><a className="text-2xl text-white" href="/issues/transportation">&raquo; Transportation</a></li>
          </ul>
        </div>
      </div>   
    </div>
  )
}
