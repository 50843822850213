import * as React from "react"

import johnGoncalvesWlneMeetTheCandidates from '../images/media/videos/john-goncalves-wlne-meet-the-candidates.webp'
import johnGoncalvesWjarRi01DemCongressionalPrimaryDebate2 from '../images/media/videos/john-goncalves-wjar-ri-01-dem-congressional-primary-debate-2.webp'
import johnGoncalvesWpriRi01DemCongressionalPrimaryDebate from '../images/media/videos/john-goncalves-wpri-ri-01-dem-congressional-primary-debate.webp'

const videos = [
  {
    "logo": `${johnGoncalvesWpriRi01DemCongressionalPrimaryDebate}`,
    "date": "8/29/2023",
    "headline": "WPRI 12 - RI-01 Democratic Congressional Primary Debate - John Goncalves - Closing Statement",
    "quote": "\"I grew up in a single-parent household.  And despite growing up in poverty, I remember when I was 12 years old, <strong>my mom would go around to every single event that Congressman Cicilline was having and say, \'Mayor, help my son &mdash; please &mdash; get into this school.\'</strong> That took me on the path from Providence Public Schools to Brown University.<br /><br />Washington isn't working for us. <strong>We need a fierce leader at the federal level who's going to fight for affordable housing.  Someone who's going to fight for our climate crisis.  Someone who's going to fight for reproductive rights.  Someone who is going to put you first.  That's exactly why I'm in this race.</strong>  And I hope I can earn your support on September 5th.\"",
    "linkUrl": "https://www.youtube.com/watch?v=RsVSVzb0pQQ&t=195s"
  },
  {
    "logo": `${johnGoncalvesWjarRi01DemCongressionalPrimaryDebate2}`,
    "date": "9/1/2023",
    "headline": "NBC 10 (WJAR) - RI-01 Democratic Congressional Primary Debate - John Goncalves - Cost of Living, Jobs, and Education",
    "quote": "\"<strong>As someone who grew up in poverty, I understand better than anybody on this stage the challenges that people are facing out there.</strong>  The cost of living is a serious, serious issue that we need to address at the federal level.<br /><br />Like some of my colleagues here, I agree &mdash; we need to address social security.  <strong>But we can't just keep the status quo on social security.  That's why at the federal level, I support the Social Security Expansion Act</strong>, which would expand social security benefits to our seniors.<br /><br /><strong>But also the Paycheck Fairness Act, which is something at the Congressional level that I would support to raise wages.  We need to make sure that we are investing in jobs and education that put people first,</strong> and that's exactly what I would do in Congress.\"",
    "linkUrl": "https://www.instagram.com/p/Cwu-wD0OvUw/"
  },
  {
    "logo": `${johnGoncalvesWlneMeetTheCandidates}`,
    "date": "8/23/2023",
    "headline": "ABC6 (WLNE) - Meet the Candidates - John Goncalves",
    "quote": "\"I think <strong>there are some distinctions</strong> in our platform.<br /><br />For example, I'm someone who supports the Social Security Expansion Act.  So &mdash; <strong>there are folks who just want to maintain the status quo on social security.  I would vote to expand it.</strong><br />...<br />When we think about <strong>the cost of housing, the cost of living</strong> in Rhode Island &mdash; these are big concerns, and <strong>I've been doing that work on the ground floor at the local level for years and years, and I think that's an important distinction</strong>.<br /><br />You know &mdash; <strong>I'm not parachuting in from somewhere else.  I've been in the community doing the work.</strong>  I'm born and bred.  You know &mdash; I grew up in Providence.  I'm a teacher at the Wheeler School.  And I think <strong>those deep-rooted connections really set me apart in this field</strong>, and I think we're going to see that play out on Election Day.\"",
    "linkUrl": "https://www.youtube.com/watch?v=C8NoOHE0YYM"
  },
]

export default function HomePageVideos() {
  return (
    <div className="relative bg-gray-100 px-4 sm:px-6 lg:px-8">  
      <div className="relative max-w-7xl mx-auto pt-8 pb-8">
        <h1 className="font-bold text-6xl">Featured Videos</h1>
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {videos.map((post) => (
            <div key={post.title} className="card flex flex-col shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="w-full object-cover" src={post.logo} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-orange-headline">
                    {post.date}
                  </p>
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.headline}</p>
                    <p className="text-lg text-gray-500" dangerouslySetInnerHTML={{ __html: post.quote }}></p>
                  </div>
                  <a href={post.linkUrl} className="block mt-2" target="_blank">Watch The Full Video Clip &raquo;</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
